/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { StreamDataContext } from '@components/context/StreamContext';
import {
  stopEngagement, getMapProcessedCommentsCount, getFeatureByQId
} from '@services/youtube-platform.service';
import { pushDataLayerForEvent } from '@lib/gtag';
import db from '@services/firebase-service';
import {
  useContext, useEffect, useState, forwardRef, useImperativeHandle
} from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import debounce from 'lodash.debounce';
import MapBoxComponent from './MapBoxComponent';
import DownArrow from '../../public/images/InteractionPage/dynamic-svg/down-arrow.svg';
import { logToCloudWatch } from '@services/logger.service';
import { handleEngagement, mapContinents } from '@services/interactions-service';

const Map = forwardRef(({
  streamId, platformType, setShowRosModel, interactionState,
  currentRosResult, currentRosFeature, interactionType, startInteraction, setCurrentFeatureId,
  moderationModeRef, activeInteractionRef, updateFSMeta,
  setShowNotification, setStartTime
}, ref) => {
  const { isResult, isRos } = interactionType;

  const {
    firestoreUnsub,
    setFirestoreUnsub,
    setMapBox,
    setOverrideDismissStatus,
    setFeatureId,
    setFeatureSettings,
  } = useContext(StreamDataContext);

  const [mapId, setMapId] = useState('');
  const [mapData, setMapData] = useState([]);
  const [viewRegion, setViewRegion] = useState({ center: [29, 9.88], zoom: 1.3 });
  const [selectedLocation, setSelectedLocation] = useState('World');
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const setPreviousData = () => {
    setMapBox({ type: 'clean' });

    if (currentRosResult.length > 0 && currentRosResult[0].features) {
      setMapBox({ type: 'set', features: currentRosResult[0].features });
      setMapData({ features: currentRosResult[0].features });
    } else {
      setMapBox({ type: 'set', features: [] });
      setMapData({ features: [] });
    }
  };

  const setupMap = ({ id, settings }) => {
    setMapId(id);
    setCurrentFeatureId(id);
    setFeatureId(id);
    setFeatureSettings(settings);
    setPreviousData();
  };

  const handleMap = async ({ interactionId = null }) => {
    const response = await handleEngagement({ streamId, platformType, currentRosFeature, interactionId });

    if (response.status && response.entity) {
      const interactionDetails = {
        id: response.entity.engagementId,
        settings: currentRosFeature.setting,
        colors: currentRosFeature.color
      };
      setupMap(interactionDetails);
      updateFSMeta({ activeInteraction: { ...interactionDetails, type: 'map' } });
    }
  };

  const unsubscribeFromFirestore = () => {
    if (firestoreUnsub.unsub) {
      firestoreUnsub.unsub();
    }
  };

  const stopMap = async () => {
    unsubscribeFromFirestore();
    const sessionId = localStorage.getItem('sessionId');
    await stopEngagement(streamId, 'map', { engagementId: mapId, platformType, sessionId });

    const response = await getFeatureByQId(currentRosFeature.id, streamId, 'map');
    if (response.entity.length > 0 && response.entity[0].features.length > 0) {
      setMapData({ features: response.entity[0].features });
      setMapBox({ type: 'set', features: response.entity[0].features });
      return;
    }
    setMapData({ features: [] });
    setMapBox({ type: 'set', features: [] });
  };

  useEffect(() => {
    if (mapId && isRos) {
      const logData = {
        streamId,
        interactionType: 'maps',
        interactionId: mapId
      };
      setFirestoreUnsub({
        unsub: onSnapshot(doc(db, 'streams', streamId, 'maps', mapId), debounce((document) => {
          logToCloudWatch('Successfully subscribed to firestore', logData, 'INFO');
          if (document.exists()) {
            const features = document.data().data.map((d) => ({
              type: 'Feature',
              geometry: d.geo_json.geometry,
              properties: d.geo_json.properties
            }));

            if (features.length > 0) setShowNotification(false);

            setMapBox({ type: 'update', features });
          }
        }, 1500, { maxWait: 1500, leading: true }), (error) => logToCloudWatch('Failed to subscribe to firestore', { ...logData, error }, 'ERROR'))
      });
    }
    return () => {
      unsubscribeFromFirestore();
    };
  }, [mapId]);

  useEffect(() => {
    if (activeInteractionRef.current && activeInteractionRef.current?.type === 'map') {
      setupMap({
        id: activeInteractionRef.current.id,
        colors: activeInteractionRef.current.colors,
        settings: activeInteractionRef.current.settings
      });
    }
  }, [activeInteractionRef.current]);

  const handleStartInteraction = () => {
    if (currentRosResult && currentRosResult.length > 0) {
      handleMap({ interactionId: currentRosResult[0].id });
    } else {
      handleMap({ interactionId: null });
    }
    setStartTime(Date.now());
  };

  const handleStopInteraction = () => {
    stopMap();
    SendDataToGoogleAnalytics();
    setMapId('');
  };

  const manageMapInteraction = () => {
    if (!isResult && startInteraction) {
      handleStartInteraction();
    } else if (mapId) {
      handleStopInteraction();
    }
  };

  useEffect(() => {
    setShowRosModel(false);
    if (moderationModeRef.current) return;

    manageMapInteraction();

    setOverrideDismissStatus(new Date().getTime());
  }, [currentRosFeature.id, startInteraction]);

  useEffect(() => {
    setPreviousData();
  }, [currentRosFeature.id, currentRosResult]);

  useEffect(() => {
    setMapBox({ type: 'viewRegion', viewRegion });
  }, [viewRegion]);

  const SendDataToGoogleAnalytics = async () => {
    const response = await getMapProcessedCommentsCount(mapId);
    if (response.status && response.entity) {
      pushDataLayerForEvent('end_magic_map_interaction', { no_count: response.entity });
    }
  };

  return (<>
    <div className={'w-full h-full'}>
      <div className={'relative h-full rounded-lg overflow-hidden'}>

        <div className='relative z-10 cursor-pointer'>
          <div
            onMouseEnter={() => setDropdownVisible(true)}
            onMouseLeave={() => setDropdownVisible(false)}
            className="flex items-center absolute left-6 top-2.5 bg-neutral-80 rounded py-2 px-3 text-neutral-50 h-8 w-38 text-sm leading-3.5 "
          >
            <p className='w-full '>{selectedLocation}</p>
            <DownArrow width='12' height="6" className="fill-current text-neutral-50 cursor-pointer ml-0" />
          </div>
          <div
            onMouseEnter={() => setDropdownVisible(true)}
            onMouseLeave={() => setDropdownVisible(false)}
            className={`${isDropdownVisible ? 'flex' : 'hidden'} flex-col gap-y-2 absolute left-6 top-10 bg-neutral-80 rounded px-3 py-2 text-neutral-50 w-38 text-sm leading-3.5`}
          >
            {mapContinents.map((continent, index) => <p key={index} onClick={() => { setViewRegion({ center: continent.coordinates, zoom: continent.zoom }); setSelectedLocation(continent.name); }} className='hover:text-white cursor-pointer text-neutral-50 font-medium font-Poppins text-sm leading-3.5 whitespace-nowrap'>{continent.name}</p>)}
          </div>
        </div>
        <MapBoxComponent
          streamId={streamId}
          mapId={mapId}
          interactionState={interactionState}
          colorData={currentRosFeature.color}
          mapData={mapData}
        />
      </div >
    </div >
  </>);
});

export default Map;
