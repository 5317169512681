import Card from '@atoms/Card';
import Image from '@atoms/Image';
import Button from '@atoms/Button';

const NoUserModal = ({ getUserNameOfCommentor }) => (
  <div className="flex flex-col items-center ">
    <Card id="NoUsers" bgColor={'bg-black'}
      className='lg:w-108 w-11/12 m-auto h-84 mt-16 relative' borderRadius='rounded-lg'>

      <div className='flex flex-col justify-center items-center pt-10'>
        <Image src='/images/SearchWheel.svg' alt={'search-wheel'} className="w-12 h-12 cursor-pointer" />
        <span className='font-Poppins text-white font-bold text-base leading-8 pt-6'>Oops! There is no enough data</span>
        <span className='font-Poppins text-white font-bold text-base leading-8 mb-11'>to run the interaction</span>

        <Button onClick={getUserNameOfCommentor} className='bg-primary-500 px-6 py-3 rounded-lg primaryActiveBtn font-Poppins text-white font-medium text-sm leading-3.5'>Refresh</Button>
      </div>

    </Card>
  </div>
);

export default NoUserModal;
