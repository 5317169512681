import { useState, useEffect } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';

const ConfettiItems = ({ isExploding, setIsExploding, setShowToast }) => {
  const [completedExplosions, setCompletedExplosions] = useState(0);
  const totalExplosions = 1;

  const bigExplodeProps = {
    force: 0.4, duration: 3000, particleCount: 50, floorHeight: 4000, floorWidth: '100vh'
  };

  const topRightSource = {
    position: 'absolute', right: '80%', left: '20%'
  };

  const topSource = {
    position: 'absolute', right: '50%', left: '50%', top: '10%'
  };

  const topLeftSource = {
    position: 'absolute', right: '20%', left: '90%'
  };

  const midSource = {
    position: 'absolute', right: '50%', left: '50%', top: '40%'
  };

  const midRightSource = {
    position: 'absolute', right: '70%', left: '30%', top: '40%'
  };

  const midLeftSource = {
    position: 'absolute', right: '30%', left: '70%', top: '40%'
  };

  const bottomRightSource = {
    position: 'absolute', right: '10%', left: '90%', bottom: '20%'
  };

  const bottomLeftSource = {
    position: 'absolute', right: '90%', left: '10%', bottom: '20%'
  };

  function handleConfettiComplete() {
    setCompletedExplosions((prevCount) => prevCount + 1);
  }

  useEffect(() => {
    if (completedExplosions === totalExplosions) {
      setIsExploding(false);
      setShowToast(true);
      return;
    }
    setIsExploding(true);
    setShowToast(false);
  }, [completedExplosions]);

  return (
    <div>
      {isExploding && (
        <>
          <div style={topRightSource}>
            <ConfettiExplosion {...bigExplodeProps} onComplete={handleConfettiComplete} />
          </div>

          <div style={topSource}>
            <ConfettiExplosion {...bigExplodeProps} />
          </div>

          <div style={topLeftSource}>
            <ConfettiExplosion {...bigExplodeProps} />
          </div>

          <div style={midRightSource}>
            <ConfettiExplosion {...bigExplodeProps} />
          </div>

          <div style={midSource}>
            <ConfettiExplosion {...bigExplodeProps} />
          </div>

          <div style={midLeftSource}>
            <ConfettiExplosion {...bigExplodeProps} />
          </div>

          <div style={bottomRightSource}>
            <ConfettiExplosion {...bigExplodeProps} />
          </div>

          <div style={bottomLeftSource}>
            <ConfettiExplosion {...bigExplodeProps} />
          </div>
        </>
      )}
    </div>
  );
};

export default ConfettiItems;
