import defaultPollOption from './defaultPollOption';

export const initialOptions = (currentRosFeature, currentRosResult, isResult) => {
  if (isResult) return currentRosFeature.comments;

  if (currentRosResult.length > 0 && currentRosResult[0].comments.length > 0) {
    if (currentRosFeature.type === 'pollWithOptions') {
      return currentRosResult[0].comments;
    }
    return [...currentRosResult[0].comments, ...defaultPollOption().slice(currentRosResult[0].comments.length)];
  }

  if (currentRosFeature.type === 'pollWithOptions') {
    return currentRosFeature.options.map(
      (option) => ({
        name: option.body, weight: 0, totalWeight: 0, count: 0, relativePercentage: 0
      })
    );
  }
  return defaultPollOption();
};

const ActionType = {
  UPDATE_OPTION: 'UPDATE_OPTION',
  DELETE_OPTION: 'DELETE_OPTION'
};

export const pollReducer = (state, action) => {
  switch (action.type) {
    case ActionType.UPDATE_OPTION:
      return [...action.options];

    case ActionType.DELETE_OPTION:
      return state.filter((option, index) => index !== action.index);

    default:
      return state;
  }
};
