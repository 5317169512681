/* eslint-disable no-nested-ternary */
import React from 'react';

const FantasticFansItem = ({
  fans,
  colors
}) => {
  return (<>
    <div
      style={{ color: colors.ffTextColor || 'white' }}
      className='w-full flex items-center justify-between py-4 text-grey-0 border-grey-400 border-b font-medium'>
      <div>Participant Name</div>
      <div className='w-[44px] text-left'>Count</div>
    </div >
    {fans.map(({ commenterName, count }) => (
      <div
        style={{ color: colors.ffTextColor || 'white' }}
        className='w-full flex items-center justify-between pt-4 font-normal'>
        <div>{commenterName}</div>
        <div className='w-[44px] text-left'>{count}</div>
      </div >
    ))}
  </>
  );
};

export default FantasticFansItem;
