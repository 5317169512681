import { v4 as uuidv4 } from 'uuid';

const defaultOption = () => {
  const options = [];
  for (let index = 1; index <= 10; index += 1) {
    options.push({
      id: uuidv4(), name: `Option ${index}`, weight: 0, totalWeight: 0, count: 0, relativePercentage: 0
    });
  }
  return options;
};

export default defaultOption;
